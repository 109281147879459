<template>
  <a-layout class="main-layout">
    <a-layout-sider
      breakpoint="md"
      :collapsed="collapsed"
      :collapsed-width="0"
      :collapsible="true"
      :trigger="null"
      @breakpoint="broken => this.collapsed = broken"
      style="height: 100vh; position: fixed; left: 0; background: #ffffff;"
    >
      <div style="height: 64px; background: #021529;">
        <svg-icon v-if="!collapsed" icon-name="logo" class-name="main-logo"></svg-icon>
      </div>
      <a-menu
        mode="inline"
        :open-keys.sync="openKeys"
        :selected-keys="selectedKeys"
        @select="({ key }) => $router.push({ name: key })"
      >
        <template v-for="menuItem in menuItems">
          <template v-if="menuItem.children">
            <a-sub-menu :key="menuItem.name">
              <template slot="title">
                <a-icon :type="menuItem.meta.icon"></a-icon>
                <span>{{ menuItem.meta.title }}</span>
              </template>
              <template v-for="child in menuItem.children">
                <a-menu-item v-if="child.meta.hidden !== true" :key="child.name">{{ child.meta.title }}</a-menu-item>
              </template>
            </a-sub-menu>
          </template>
          <template v-else>
            <a-menu-item v-if="menuItem.meta.hidden !== true" :key="menuItem.name">
              <a-icon :type="menuItem.meta.icon"></a-icon>
              <span>{{ menuItem.meta.title }}</span>
            </a-menu-item>
          </template>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout :style="{ marginLeft: collapsed ? 0 : '200px' }">
      <a-layout-header :style="{
        width: this.collapsed ? '100%' : 'calc(100% - 200px)',
        position: 'fixed',
        padding: 0,
        background: '#ffffff',
        color: 'rgba(0, 0, 0, 0.65)',
        zIndex: 100
      }">
        <div style="padding: 0 16px;">
          <div style="font-size: 16px; float: left;">
            <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="collapsed = !collapsed"></a-icon>
          </div>
          <a-space size="large" style="float: right;">
            <router-link :to="{ name: 'BigScreen' }">
              <a-tooltip title="综合大屏">
                <a-icon type="desktop" style="color: rgba(0, 0, 0, 0.65); font-size: 16px;"></a-icon>
              </a-tooltip>
            </router-link>
            <dropdown-menu></dropdown-menu>
          </a-space>
        </div>
      </a-layout-header>

      <div style="margin-top: 64px;width:100%">
        <breadcrumb style="margin: 16px;"></breadcrumb>
        <a-layout-content :style="{ minHeight: 'calc(100vh - 186px)', margin: '0 16px' }">
          <router-view></router-view>
          <a-back-top></a-back-top>
        </a-layout-content>
        <layout-footer></layout-footer>
      </div>
    </a-layout>
  </a-layout>
</template>

<script>
import io from 'socket.io-client'
import { mapGetters } from 'vuex'
import LayoutFooter from '@/components/LayoutFooter'
import config from '@/config'
import Breadcrumb from './modules/Breadcrumb'
import DropdownMenu from './modules/DropdownMenu'

export default {
  name: 'MainLayout',
  components: {
    Breadcrumb,
    DropdownMenu,
    LayoutFooter
  },
  data () {
    return {
      collapsed: false,
      openKeys: [],
      cachedOpenKeys: [],
      selectedKeys: [],
      socket: null
    }
  },
  computed: {
    ...mapGetters(['menuRoutes']),
    menuItems () {
      return this.menuRoutes
    }
  },
  mounted () {
    this.updateMenu()
    this.initSocket()
  },
  methods: {
    initSocket () {
      this.socket = io(config.baseURL, { transports: ['websocket'] })
      this.socket.on('alert-event', data => {
        data.forEach(alertEvent => {
          setTimeout(() => {
            this.$notification.warning({
              message: '告警事件',
              description: h => {
                return h('a', {
                  attrs: { href: `/alert/event/${alertEvent.id}/detail` }
                }, alertEvent.name)
              },
              duration: 5,
              icon: h => {
                return h('a-icon', {
                  attrs: { type: 'alert' },
                  style: { color: '#f78362' }
                })
              }
            })
          }, 1000)
        })
      })
    },
    updateMenu () {
      const routes = this.$route.matched.concat()
      const openKeys = routes.map(r => r.name)
      if (this.collapsed) {
        this.cachedOpenKeys = openKeys
      } else {
        this.openKeys = openKeys
      }
      this.selectedKeys = [routes.pop().name]
    }
  },
  watch: {
    collapsed (val) {
      if (val) {
        this.cachedOpenKeys = this.openKeys
        this.openKeys = []
      } else {
        this.openKeys = this.cachedOpenKeys
      }
    },
    $route () {
      this.updateMenu()
    }
  },
  destroyed () {
    if (this.socket) this.socket.disconnect()
  }
}
</script>

<style lang="less">
.main-layout {
  min-height: 100vh;

  .ant-layout-sider {
    overflow: auto;
  }
}
.main-logo {
  width: 136px;
  height: 64px;
  margin: 0 32px;
  color: #ffffff;
}
</style>
