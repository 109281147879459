import axios from 'axios'
import message from 'ant-design-vue/es/message'
import storage from 'store'
import store from '@/store'
import config from '@/config'

const request = axios.create({
  baseURL: `${config.baseURL}/api/v1`,
  timeout: config.timeout
})

const errorHandler = error => {
  const data = error.response.data
  message.error(data.message)
  if (data.code === 401) {
    const accessToken = storage.get('ACCESS_TOKEN')
    if (accessToken) {
      store.dispatch('Logout').then(() => {
        setTimeout(() => {
          window.location.reload()
        }, 300)
      })
    }
  }
  return Promise.reject(error)
}

request.interceptors.request.use(value => {
  const accessToken = storage.get('ACCESS_TOKEN')
  if (accessToken) value.headers.Authorization = `Bearer ${accessToken}`
  return value
}, errorHandler)

request.interceptors.response.use(value => value.data, errorHandler)

export default request
