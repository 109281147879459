<template>
  <a-layout class="auth-layout">
    <a-layout-content style="display: flex; align-items: center; justify-content: center;">
      <a-card class="auth-content" :body-style="{ padding: '80px' }" :bordered="false" :hoverable="true">
        <div style="text-align: center; margin-bottom: 24px;">
          <svg-icon icon-name="logo" class-name="auth-logo"></svg-icon>
        </div>

        <div style="margin-bottom: 24px;">
          <div style="font-size: 32px; font-weight: 200;">欢迎使用</div>
          <div style="font-size: 32px; font-weight: 800;">{{ title }}</div>
        </div>
        <router-view></router-view>
      </a-card>
    </a-layout-content>
    <layout-footer></layout-footer>
  </a-layout>
</template>

<script>
import LayoutFooter from '@/components/LayoutFooter'
import config from '@/config'

export default {
  name: 'AuthLayout',
  components: { LayoutFooter },
  data () {
    return {
      title: config.title
    }
  }
}
</script>

<style lang="less">
.auth-layout {
  min-height: 100vh;

  .auth-logo {
    height: 48px;
    color: #3d7eff;
  }

  .auth-content {
    width: 640px;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 10px 10px 15px #cbced1, -10px -10px 15px #fff;
  }
}
</style>
