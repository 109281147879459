var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout',{staticClass:"main-layout"},[_c('a-layout-sider',{staticStyle:{"height":"100vh","position":"fixed","left":"0","background":"#ffffff"},attrs:{"breakpoint":"md","collapsed":_vm.collapsed,"collapsed-width":0,"collapsible":true,"trigger":null},on:{"breakpoint":function (broken) { return this$1.collapsed = broken; }}},[_c('div',{staticStyle:{"height":"64px","background":"#021529"}},[(!_vm.collapsed)?_c('svg-icon',{attrs:{"icon-name":"logo","class-name":"main-logo"}}):_vm._e()],1),_c('a-menu',{attrs:{"mode":"inline","open-keys":_vm.openKeys,"selected-keys":_vm.selectedKeys},on:{"update:openKeys":function($event){_vm.openKeys=$event},"update:open-keys":function($event){_vm.openKeys=$event},"select":function (ref) {
    var key = ref.key;

    return _vm.$router.push({ name: key });
}}},[_vm._l((_vm.menuItems),function(menuItem){return [(menuItem.children)?[_c('a-sub-menu',{key:menuItem.name},[_c('template',{slot:"title"},[_c('a-icon',{attrs:{"type":menuItem.meta.icon}}),_c('span',[_vm._v(_vm._s(menuItem.meta.title))])],1),_vm._l((menuItem.children),function(child){return [(child.meta.hidden !== true)?_c('a-menu-item',{key:child.name},[_vm._v(_vm._s(child.meta.title))]):_vm._e()]})],2)]:[(menuItem.meta.hidden !== true)?_c('a-menu-item',{key:menuItem.name},[_c('a-icon',{attrs:{"type":menuItem.meta.icon}}),_c('span',[_vm._v(_vm._s(menuItem.meta.title))])],1):_vm._e()]]})],2)],1),_c('a-layout',{style:({ marginLeft: _vm.collapsed ? 0 : '200px' })},[_c('a-layout-header',{style:({
      width: this.collapsed ? '100%' : 'calc(100% - 200px)',
      position: 'fixed',
      padding: 0,
      background: '#ffffff',
      color: 'rgba(0, 0, 0, 0.65)',
      zIndex: 100
    })},[_c('div',{staticStyle:{"padding":"0 16px"}},[_c('div',{staticStyle:{"font-size":"16px","float":"left"}},[_c('a-icon',{attrs:{"type":_vm.collapsed ? 'menu-unfold' : 'menu-fold'},on:{"click":function($event){_vm.collapsed = !_vm.collapsed}}})],1),_c('a-space',{staticStyle:{"float":"right"},attrs:{"size":"large"}},[_c('router-link',{attrs:{"to":{ name: 'BigScreen' }}},[_c('a-tooltip',{attrs:{"title":"综合大屏"}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.65)","font-size":"16px"},attrs:{"type":"desktop"}})],1)],1),_c('dropdown-menu')],1)],1)]),_c('div',{staticStyle:{"margin-top":"64px","width":"100%"}},[_c('breadcrumb',{staticStyle:{"margin":"16px"}}),_c('a-layout-content',{style:({ minHeight: 'calc(100vh - 186px)', margin: '0 16px' })},[_c('router-view'),_c('a-back-top')],1),_c('layout-footer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }