<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view></router-view>
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN'
import moment from 'moment'

import 'moment/locale/zh-cn'

moment.locale('zh-cn')

export default {
  data () {
    return {
      locale: zhCN
    }
  }
}
</script>

<style lang="less">
.ant-card-hoverable {
  cursor: default;
}
.ant-form-item {
  margin-bottom: 16px;
}
.ant-table-small {
  border: none;
}
</style>
