<template>
  <a-tooltip placement="topLeft">
    <div slot="title"
         style="font-size: 12px;">
      <template v-if="sourceId">
        <div>
          <a-space style="align-items: baseline;">
            <span style="white-space: nowrap;">节点名称: </span>
            <span>{{ source.name }}</span>
          </a-space>
        </div>
        <div v-if="sourceType === 'middleware' || sourceType === 'database'">
          <a-space>
            <span>所属主机: </span>
            <span>{{ source.host ? source.host.name : '-' }}</span>
          </a-space>
        </div>
        <div v-else>
          <a-space>
            <span>监控地址: </span>
            <span>{{ source.monitor_address || '-' }}</span>
          </a-space>
        </div>
        <div>
          <a-space>
            <span>当前告警: </span>
            <span>{{ count.unrecovered }}</span>
          </a-space>
        </div>
        <monitor-item v-for="item in monitorItems"
                      :key="item.key"
                      :item-key="item.key"
                      :sourceType="sourceType"
                      :sourceId="sourceId"></monitor-item>
      </template>
      <div v-else>{{ name }}</div>
    </div>

    <a-space style="line-height: 1;">
      <svg-icon :icon-name="iconName"
                class-name="topology-logo"
                :style="count.unrecovered ? { color, animation: 'alert 3s infinite' } : { color }"></svg-icon>
      <div style="width: 100px; font-size: 12px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; color: #bfbfbf;">{{ source.name }}</div>
    </a-space>
  </a-tooltip>
</template>

<script>
import { getAlertEventCount } from '@/api/alert-event'
import { getDatabase } from '@/api/database'
import { getHost } from '@/api/host'
import { getWeb } from '@/api/web'
import { getMiddleware } from '@/api/middleware'
import MonitorItem from './modules/MonitorItem'

export default {
  name: 'SourceNode',
  inject: ['getGraph', 'getNode'],
  components: { MonitorItem },
  data () {
    return {
      name: '',
      iconName: '',
      sourceType: '',
      sourceId: '',
      source: { active: false },
      count: { recovered: 0, unrecovered: 0 },
      monitorItems: [],
      interval: null
    }
  },
  computed: {
    color () {
      if (this.sourceId) {
        if (this.source.active) {
          return this.count.unrecovered ? '#f35f71' : '#00d886'
        } else {
          return '#bfbfbf'
        }
      } else {
        return '#1890ff'
      }
    }
  },
  mounted () {
    const node = this.getNode()
    const data = node.getData()
    this.name = data.name
    this.iconName = data.iconName
    this.sourceType = data.sourceType
    this.sourceId = data.sourceId
    this.monitorItems = data.monitorItems
  },
  methods: {
    fetch () {
      switch (this.sourceType) {
        case 'host':
          getHost(this.sourceId).then((res) => {
            this.source = res.data
            if (this.source.os_type !== 'unknown') {
              this.iconName = this.source.os_type
            }
          })
          break
        case 'middleware':
          getMiddleware(this.sourceId).then((res) => {
            this.source = res.data
            this.iconName = this.source.middleware_type
          })
          break
        case 'database':
          getDatabase(this.sourceId).then((res) => {
            this.source = res.data
            this.iconName = this.source.database_type
          })
          break
        case 'web':
          getWeb(this.sourceId).then((res) => {
            this.source = res.data
            this.iconName = 'web'
          })
          break
      }
    },
    fetchAlert () {
      getAlertEventCount({
        count_by: 'recovered',
        source_type: this.sourceType,
        source_id: this.sourceId
      }).then((res) => {
        this.count = { recovered: 0, unrecovered: 0 }
        res.data.data.forEach((item) => {
          this.count[item.name] = item.value
        })
      })
    }
  },
  watch: {
    sourceId (val) {
      if (val) {
        this.fetch()
        this.fetchAlert()
        this.interval = setInterval(this.fetchAlert, 60 * 1000)
      }
    }
  },
  destroyed () {
    clearInterval(this.interval)
  }
}
</script>

<style lang="less">
.topology-logo {
  width: 26px;
  height: 26px;
}
@keyframes alert {
  0% {
    color: #00d886;
  }
  10% {
    color: #f35f71;
  }
  80% {
    color: #f35f71;
  }
  100% {
    color: #00d886;
  }
}
</style>
