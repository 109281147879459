<template>
  <svg :aria-hidden="true" :class="className ? className : 'svg-icon'">
    <use :xlink:href="`#icon-${this.iconName}`"></use>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    iconName: { type: String, required: true },
    className: { type: String, default: '' }
  }
}
</script>

<style lang="less">
.svg-icon {
  width: 100%;
  height: 100%;
}
</style>
