import request from '@/utils/request'

const urlPrefix = '/alert-events'

export function getAlertEvent (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateAlertEvent (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function getAlertEventList (params) {
  return request.get(urlPrefix, { params })
}

export function batchUpdateAlertEvent (data) {
  return request.put(`${urlPrefix}/batch/update`, data)
}

export function getAlertEventCount (params) {
  return request.get(`${urlPrefix}/count`, { params })
}

export function getAlertEventTop (params) {
  return request.get(`${urlPrefix}/top`, { params })
}

export function getAlertEventTrend (params) {
  return request.get(`${urlPrefix}/trend`, { params })
}

export function getAlertEventSnapshotList (id) {
  return request.get(`${urlPrefix}/${id}/snapshots`)
}
