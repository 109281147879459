import { Node } from '@antv/x6'
export class Group extends Node {
  constructor (x, y) {
    super(x, y)
    this.collapsed = false
    this.expandSize = { height: 0, width: 0 }
  }

  postprocess () {
    this.toggleCollapse(false)
  }

  isCollapsed () {
    return this.collapsed
  }

  toggleCollapse (collapsed) {
    const target = collapsed == null ? !this.collapsed : collapsed
    if (target) {
      this.attr('buttonSign', { d: 'M 1 5 9 5 M 5 1 5 9' })
      this.expandSize = this.getSize()
      this.resize(100, 32)
    } else {
      this.attr('buttonSign', { d: 'M 2 5 8 5' })
      if (this.expandSize) {
        this.resize(this.expandSize.width, this.expandSize.height)
      }
    }
    this.collapsed = target
  }
}
Group.config({
  shape: 'group',
  width: 36,
  height: 36,
  data: { parent: true },
  markup: [
    { tagName: 'rect', selector: 'body' },
    { tagName: 'text', selector: 'label' },
    {
      tagName: 'g',
      selector: 'buttonGroup',
      children: [
        { tagName: 'rect', selector: 'button', attrs: { 'pointer-events': 'visiblePainted' } },
        { tagName: 'path', selector: 'buttonSign', attrs: { fill: 'none', 'pointer-events': 'none' } }
      ]
    }
  ],
  attrs: {
    label: {
      refY: 10,
      refX: 32,
      fontSize: 12,
      fill: 'rgba(60, 100, 225, 0.75)'
    },
    body: {
      fill: 'rgba(0, 0, 0, 0)',
      stroke: 'rgba(60, 100, 225, 0.75)',
      refWidth: '100%',
      refHeight: '100%',
      strokeWidth: 1,
      strokeDasharray: 1
    },
    buttonGroup: { refX: 8, refY: 8 }
  }
})

const attrs = {
  circle: {
    r: 4,
    magnet: true,
    stroke: 'rgba(0, 0, 0, 0.45)',
    strokeWidth: 0.5,
    style: { visibility: 'hidden' }
  }
}
function sourceNode (name, iconName, sourceType, monitorItems = []) {
  return {
    shape: 'vue-shape',
    width: 36,
    height: 36,
    component: 'source-node',
    data: {
      name,
      iconName,
      sourceType,
      sourceId: '',
      parent: false,
      monitorItems
    },
    ports: {
      groups: {
        top: { attrs, position: 'top' },
        bottom: { attrs, position: 'bottom' },
        left: { attrs, position: 'left' },
        right: { attrs, position: 'right' }
      },
      items: [
        { group: 'top' },
        { group: 'bottom' },
        { group: 'left' },
        { group: 'right' }
      ]
    }
  }
}

export function getSourceNodeList (graph, name) {
  switch (name) {
    case 'host':
      return [
        graph.createNode(sourceNode('Linux', 'linux', 'host')),
        graph.createNode(sourceNode('Windows', 'windows', 'host'))
      ]
    case 'middleware':
      return [
        graph.createNode(sourceNode('Nginx', 'nginx', 'middleware')),
        graph.createNode(sourceNode('RabbitMQ', 'rabbitmq', 'middleware'))
      ]
    case 'database':
      return [
        graph.createNode(sourceNode('MySQL', 'mysql', 'database')),
        graph.createNode(sourceNode('PostgreSQL', 'postgresql', 'database')),
        graph.createNode(sourceNode('Oracle', 'oracle', 'database')),
        graph.createNode(sourceNode('MSSQL', 'mssql', 'database')),
        graph.createNode(sourceNode('Redis', 'redis', 'database')),
        graph.createNode(sourceNode('Memcached', 'memcached', 'database')),
        graph.createNode(sourceNode('MongoDB', 'mongodb', 'database')),
        graph.createNode(sourceNode('Elasticsearch', 'elasticsearch', 'database')),
        graph.createNode(sourceNode('接口', 'web', 'web'))
      ]
    case 'base':
      return [new Group(0, 0),
        graph.createNode(sourceNode('接口', 'web', 'web')),
        graph.createNode({
          width: 36,
          height: 36,
          data: { isText: true },
          attrs: {
            label: {
              text: '文本',
              fontSize: 12,
              fill: 'rgba(60, 100, 225, 0.75)'
            },
            body: {
              fill: 'rgba(0,0,0,0)',
              stroke: 'rgba(60, 100, 225, 0)'
            }
          },
          ports: {
            groups: {
              top: { attrs, position: 'top' },
              bottom: { attrs, position: 'bottom' },
              left: { attrs, position: 'left' },
              right: { attrs, position: 'right' }
            },
            items: [
              { group: 'top' },
              { group: 'bottom' },
              { group: 'left' },
              { group: 'right' }
            ]
          }
        })]
    default:
      return []
  }
}
