import { getDatabaseCount, getDatabaseList, getDatabaseMonitorHistory, getDatabaseMonitorItemList } from '@/api/database'
import { getHostCount, getHostList, getHostMonitorHistory, getHostMonitorItemList } from '@/api/host'
import { getMiddlewareCount, getMiddlewareList, getMiddlewareMonitorHistory, getMiddlewareMonitorItemList } from '@/api/middleware'
import { getWebCount, getWebList, getWebMonitorHistory, getWebMonitorItemList } from '@/api/web'
import store from '@/store'
import moment from 'moment'

export function hasPermission (permission) {
  if (store.getters.admin) return true
  return store.getters.permissions.indexOf(permission) !== -1
}

export function sourceTypes () {
  return [
    { title: '主机', value: 'host' },
    { title: '中间件', value: 'middleware' },
    { title: '数据库', value: 'database' },
    { title: '接口', value: 'web' }
  ]
}

export function severityColor (severity) {
  switch (severity) {
    case 'disaster': return '#f35f71'
    case 'high': return '#f6bd16'
    case 'average': return '#3392ff'
    default: return '#254cd9'
  }
}

export function humanTime (second) {
  if (second / 60 < 1) {
    return `${second} 秒`
  } else {
    const minute = ~~(second / 60)
    if (minute / 60 < 1) {
      return `${minute} 分钟`
    } else {
      const hour = ~~(minute / 60)
      if (hour / 24 < 1) {
        return `${hour} 小时`
      } else {
        return `${~~(hour / 24)} 天`
      }
    }
  }
}

export function humanValue (value) {
  let v = value
  if (v / 1000 < 1) return v
  v = v / 1000
  if (v / 1000 < 1) return `${v.toFixed(2)} K`
  v = v / 1000
  if (v / 1000 < 1) return `${v.toFixed(2)} M`
  return `${v.toFixed(2) / 1000} G`
}

export function getSourceMonitorHistoryFunc (sourceType) {
  switch (sourceType) {
    case 'middleware':
      return getMiddlewareMonitorHistory
    case 'database':
      return getDatabaseMonitorHistory
    case 'web':
      return getWebMonitorHistory
    default:
      return getHostMonitorHistory
  }
}

export function getSourceMonitorListFunc (sourceType) {
  switch (sourceType) {
    case 'middleware':
      return getMiddlewareMonitorItemList
    case 'database':
      return getDatabaseMonitorItemList
    case 'web':
      return getWebMonitorItemList
    default:
      return getHostMonitorItemList
  }
}

export function getSourceListFunc (sourceType) {
  switch (sourceType) {
    case 'middleware':
      return getMiddlewareList
    case 'database':
      return getDatabaseList
    case 'web':
      return getWebList
    default:
      return getHostList
  }
}

export function getSourceCountFunc (sourceType) {
  switch (sourceType) {
    case 'middleware':
      return getMiddlewareCount
    case 'database':
      return getDatabaseCount
    case 'web':
      return getWebCount
    default:
      return getHostCount
  }
}

export function getSourceListRouterName (sourceType) {
  switch (sourceType) {
    case 'middleware':
      return 'MiddlewareList'
    case 'database':
      return 'DatabaseList'
    case 'web':
      return 'WebList'
    default:
      return 'HostList'
  }
}

export function getSourceRouterName (sourceType) {
  switch (sourceType) {
    case 'middleware':
      return 'MiddlewareDetail'
    case 'database':
      return 'DatabaseDetail'
    case 'web':
      return 'WebDetail'
    default:
      return 'HostDetail'
  }
}

export function getDatetimeRanges () {
  return {
    '5分钟': [moment().subtract(5, 'minute'), moment()],
    '15分钟': [moment().subtract(15, 'minute'), moment()],
    '1小时': [moment().subtract(1, 'hour'), moment()],
    '3小时': [moment().subtract(3, 'hour'), moment()],
    '6小时': [moment().subtract(6, 'hour'), moment()],
    '1天': [moment().subtract(1, 'day'), moment()]
  }
}

export function subTitle (title, length) {
  let len = 0
  let substr = ''
  for (let i = 0; i < title.length; i++) {
    if (len < length) {
      substr += title[i]
      if (title.charCodeAt(i) > 127 || title.charCodeAt(i) === 94) {
        len += 2
      } else {
        len++
      }
    } else {
      substr += '...'
      break
    }
  }
  return substr
}
