import Vue from 'vue'

import '@/assets/icons'
import '@/libs/antd'
import '@/libs/datav'

import App from '@/App'
import i18n from '@/locale'
import router from '@/router'
import store from '@/store'

import '@antv/x6-vue-shape'
import SourceNode from '@/components/topology/SourceNode'
import { Graph } from '@antv/x6'
import { Group } from '@/utils/topology'

Graph.registerVueComponent('source-node', {
  template: '<source-node></source-node>',
  components: { SourceNode }
}, true)

Graph.registerNode('group', Group)

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
