import request from '@/utils/request'

const urlPrefix = '/databases'

export function getDatabase (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateDatabase (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function getDatabaseList (params) {
  return request.get(urlPrefix, { params })
}

export function batchUpdateDatabase (data) {
  return request.put(`${urlPrefix}/batch/update`, data)
}

export function getDatabaseCount (params) {
  return request.get(`${urlPrefix}/count`, { params })
}

export function getDatabaseMonitorItemList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/items`, { params })
}

export function getDatabaseMonitorHistory (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/history`, { params })
}

export function updateDatabaseAuth (id, data) {
  return request.post(`${urlPrefix}/${id}/auth`, data)
}

export function getDatabasePreferredMonitorItemList (id) {
  return request.get(`${urlPrefix}/${id}/preferred/monitor/items`)
}

export function getDatabaseMonitorPerformance (params) {
  return request.get(`${urlPrefix}/monitor/performance`, { params })
}
