import request from '@/utils/request'

const urlPrefix = '/webs'

export function getWeb (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateWeb (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteWeb (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getWebList (params) {
  return request.get(urlPrefix, { params })
}

export function createWeb (data) {
  return request.post(urlPrefix, data)
}

export function batchUpdateWeb (data) {
  return request.put(`${urlPrefix}/batch/update`, data)
}

export function getWebCount (params) {
  return request.get(`${urlPrefix}/count`, { params })
}

export function getWebMonitorHistory (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/history`, { params })
}

export function getWebMonitorItemList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/items`, { params })
}
