<template>
  <a-breadcrumb>
    <a-breadcrumb-item v-for="route in $route.matched" :key="route.name">
      <router-link v-if="route.name !== $route.name" :to="{ name: route.name }">{{ route.meta.title }}</router-link>
      <span v-else style="user-select: none;">{{ route.meta.title }}</span>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
export default {
  name: 'Breadcrumb'
}
</script>
