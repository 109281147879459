import NProgress from 'nprogress'
import Vue from 'vue'
import VueRouter from 'vue-router'
import storage from 'store'

import config from '@/config'
import store from '@/store'
import 'nprogress/nprogress.css'

import AuthLayout from '@/layouts/AuthLayout'
import MainLayout from '@/layouts/MainLayout'

NProgress.configure({ showSpinner: false })

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/auth',
      name: 'Auth',
      component: AuthLayout,
      redirect: '/auth/login',
      children: [
        { path: 'login', name: 'Login', component: () => import('@/views/auth/Login') }
      ]
    },
    {
      path: '/external',
      name: 'External',
      component: MainLayout,
      children: [
        { path: 'source/host/list', name: 'ExternalHostList', meta: { title: '主机' }, component: () => import('@/views/external/HostList') },
        { path: 'alert/event/list', name: 'ExternalAlertEventList', meta: { title: '故障' }, component: () => import('@/views/external/AlertEventList') }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  document.title = config.title
  if (storage.get('ACCESS_TOKEN')) {
    if (store.getters.nickname === '') {
      store.dispatch('GetProfile').then(res => {
        store.dispatch('GenerateRoutes', res.data).then(() => {
          store.getters.routes.forEach(route => {
            router.addRoute(route)
          })
          next({ ...to })
        })
      })
    }
    if (to.name === 'Login') {
      next({ name: 'BigScreen' })
    } else {
      let redirect = decodeURIComponent(from.query.redirect || to.path)
      if (redirect === '/') redirect = '/big-screen'
      if (to.path === redirect) {
        next()
      } else {
        next({ path: redirect })
      }
    }
  } else {
    // 免密
    if (to.name === 'Login' || to.name === 'ExternalHostList' || to.name === 'ExternalAlertEventList') {
      next()
    } else {
      next({ name: 'Login', query: { redirect: to.fullPath } })
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
