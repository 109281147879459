import request from '@/utils/request'

const urlPrefix = '/hosts'

export function getHost (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateHost (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function getHostList (params) {
  return request.get(urlPrefix, { params })
}

export function batchUpdateHost (data) {
  return request.put(`${urlPrefix}/batch/update`, data)
}

export function getHostCount (params) {
  return request.get(`${urlPrefix}/count`, { params })
}

export function getHostTop (params) {
  return request.get(`${urlPrefix}/top`, { params })
}

export function getHostMonitorItemList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/items`, { params })
}

export function getHostMonitorHistory (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/history`, { params })
}

export function getHostProcessList (id) {
  return request.get(`${urlPrefix}/${id}/processes`)
}

export function getHostPortList (id) {
  return request.get(`${urlPrefix}/${id}/ports`)
}

export function getHostPreferredMonitorItemList (id) {
  return request.get(`${urlPrefix}/${id}/preferred/monitor/items`)
}

export function getHostMonitorPerformance (params) {
  return request.get(`${urlPrefix}/monitor/performance`, { params })
}
