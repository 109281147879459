<template>
  <a-space>
    <span>{{ item.name }}: </span>
    <span>{{ item.last_value }}</span>
  </a-space>
</template>

<script>
import { getSourceMonitorListFunc } from '@/utils'

export default {
  name: 'MonitorItem',
  props: {
    itemKey: { type: String, required: true },
    sourceType: { type: String, required: true },
    sourceId: { type: String, required: true }
  },
  data () {
    return {
      item: {}
    }
  },
  mounted () {
    if (this.itemKey) this.fetch()
  },
  methods: {
    fetch () {
      const monitorListFunc = getSourceMonitorListFunc(this.sourceType)
      monitorListFunc(this.sourceId, { key: this.itemKey }).then(res => {
        const data = res.data
        if (data.total) this.item = data.data[0]
      })
    }
  }
}
</script>
