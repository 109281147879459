import request from '@/utils/request'

const urlPrefix = '/middlewares'

export function getMiddleware (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateMiddleware (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function getMiddlewareList (params) {
  return request.get(urlPrefix, { params })
}

export function batchUpdateMiddleware (data) {
  return request.put(`${urlPrefix}/batch/update`, data)
}

export function getMiddlewareCount (params) {
  return request.get(`${urlPrefix}/count`, { params })
}

export function getMiddlewareMonitorItemList (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/items`, { params })
}

export function getMiddlewareMonitorHistory (id, params) {
  return request.get(`${urlPrefix}/${id}/monitor/history`, { params })
}

export function getMiddlewarePreferredMonitorItemList (id) {
  return request.get(`${urlPrefix}/${id}/preferred/monitor/items`)
}

export function getMiddlewareMonitorPerformance (params) {
  return request.get(`${urlPrefix}/monitor/performance`, { params })
}
