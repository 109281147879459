import storage from 'store'

import { getUserProfile, login } from '@/api/user'

export default {
  state: {
    accessToken: '',
    nickname: ''
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, accessToken) => { state.accessToken = accessToken },
    SET_NICKNAME: (state, nickname) => { state.nickname = nickname }
  },
  actions: {
    Login ({ commit }, data) {
      return new Promise((resolve, reject) => {
        login(data).then(res => {
          const accessToken = res.data.access_token
          commit('SET_ACCESS_TOKEN', accessToken)
          storage.set('ACCESS_TOKEN', accessToken, { expires: 86400 * 1000 })
          resolve(res)
        }).catch(error => reject(error))
      })
    },
    GetProfile ({ commit }) {
      return new Promise((resolve, reject) => {
        getUserProfile().then(res => {
          const data = res.data
          commit('SET_NICKNAME', data.nickname)
          resolve(res)
        }).catch(error => reject(error))
      })
    },
    Logout ({ commit }) {
      return new Promise(resolve => {
        commit('SET_ACCESS_TOKEN', '')
        storage.remove('ACCESS_TOKEN')
        commit('SET_NICKNAME', '')
        resolve()
      })
    }
  }
}
