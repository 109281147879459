import BlankLayout from '@/layouts/BlankLayout'
import MainLayout from '@/layouts/MainLayout'

export default {
  state: {
    admin: false,
    permissions: [],
    routes: [],
    menuRoutes: []
  },
  mutations: {
    SET_ADMIN: (state, admin) => { state.admin = admin },
    SET_PERMISSIONS: (state, permissions) => { state.permissions = permissions },
    SET_ROUTES: (state, routes) => { state.routes = routes },
    SET_MENU_ROUTES: (state, routes) => { state.menuRoutes = routes }
  },
  actions: {
    GenerateRoutes ({ commit }, data) {
      const admin = data.admin || false
      commit('SET_ADMIN', admin)

      const permissions = []
      const permission = (data.role && data.role.permission) || {}
      Object.keys(permission).forEach(key => {
        permission[key].forEach(item => {
          permissions.push(`${key}.${item}`)
        })
      })
      commit('SET_PERMISSIONS', permissions)

      const routes = [
        { path: '/big-screen', name: 'BigScreen', component: () => import('@/views/BigScreen') },
        { path: '/project/:id/topology', name: 'ProjectTopology', component: () => import('@/views/project/ProjectTopology') }
      ]
      const menuRoutes = [{
        path: '/dashboard',
        name: 'Dashboard',
        meta: { icon: 'dashboard', title: '系统墙' },
        component: () => import('@/views/dashboard/ProjectStatistic')
      }]

      const sourceRoutes = [
        { path: 'host/list', name: 'HostList', meta: { title: '主机' }, component: () => import('@/views/host/HostList') },
        { path: 'host/:id/detail', name: 'HostDetail', meta: { title: '主机详情', hidden: true }, component: () => import('@/views/host/HostDetail') },
        { path: 'middleware/list', name: 'MiddlewareList', meta: { title: '中间件' }, component: () => import('@/views/middleware/MiddlewareList') },
        { path: 'middleware/:id/detail', name: 'MiddlewareDetail', meta: { title: '中间件详情', hidden: true }, component: () => import('@/views/middleware/MiddlewareDetail') },
        { path: 'database/list', name: 'DatabaseList', meta: { title: '数据库' }, component: () => import('@/views/database/DatabaseList') },
        { path: 'database/:id/detail', name: 'DatabaseDetail', meta: { title: '数据库详情', hidden: true }, component: () => import('@/views/database/DatabaseDetail') },
        { path: 'web/list', name: 'WebList', meta: { title: '接口' }, component: () => import('@/views/web/WebList') },
        { path: 'web/:id/detail', name: 'WebDetail', meta: { title: '接口详情', hidden: true }, component: () => import('@/views/web/WebDetail') }
      ]
      if (data.admin) {
        sourceRoutes.push(
          { path: 'ip-subnet/list', name: 'IPSubnetList', meta: { title: '地址资源' }, component: () => import('@/views/ip-subnet/IPSubnetList') },
          { path: 'ip-subnet/:id/detail', name: 'IPSubnetDetail', meta: { title: '地址资源详情', hidden: true }, component: () => import('@/views/ip-subnet/IPSubnetDetail') }
        )
      }
      menuRoutes.push({
        path: '/source',
        name: 'Source',
        meta: { icon: 'appstore', title: '资产管理' },
        component: BlankLayout,
        redirect: `/source/${sourceRoutes[0].path}`,
        children: sourceRoutes
      })

      menuRoutes.push(
        {
          path: 'performance/management',
          name: 'PerformanceManagement',
          meta: { icon: 'fund', title: '性能管理' },
          component: () => import('@/views/performance')
        }
      )

      menuRoutes.push(
        {
          path: '/alert',
          name: 'AlertEvent',
          meta: { icon: 'alert', title: '故障管理' },
          component: BlankLayout,
          redirect: '/alert/event/statistic',
          children: [
            { path: 'event/statistic', name: 'AlertStatistic', meta: { title: '告警统计' }, component: () => import('@/views/AlertStatistic') },
            { path: 'event/realtime/list', name: 'RealtimeAlert', meta: { title: '实时告警' }, component: () => import('@/views/alert-realtime/AlertEventList') },
            { path: 'event/history/list', name: 'HistoricalAlert', meta: { title: '历史告警' }, component: () => import('@/views/alert-historical/AlertEventList') },
            { path: 'event/:id/detail', name: 'AlertEventDetail', meta: { title: '告警详情', hidden: true }, component: () => import('@/views/alert-historical/AlertEventDetail') }
          ]
        }
      )
      if (data.admin) {
        menuRoutes.push(
          {
            path: '/audit',
            name: 'Audit',
            meta: { icon: 'lock', title: '日志管理' },
            component: BlankLayout,
            redirect: '/audit/login-log',
            children: [
              { path: 'login-log', name: 'LoginLog', meta: { title: '登陆日志' }, component: () => import('@/views/LoginLog') },
              { path: 'operation-log', name: 'OperationLog', meta: { title: '操作日志' }, component: () => import('@/views/OperationLog') }
            ]
          },
          {
            path: '/setting',
            name: 'Setting',
            meta: { icon: 'setting', title: '配置管理' },
            component: BlankLayout,
            redirect: '/setting/user/list',
            children: [
              { path: 'organization/list', name: 'OrganizationList', meta: { title: '组织管理' }, component: () => import('@/views/organization/OrganizationList') },
              { path: 'zone/list', name: 'ZoneList', meta: { title: '域管理' }, component: () => import('@/views/zone/ZoneList') },
              { path: 'project/list', name: 'SystemList', meta: { title: '系统管理' }, component: () => import('@/views/project/ProjectList') },
              { path: 'project/:id/detail', name: 'SystemDetail', meta: { title: '系统详情', hidden: true }, component: () => import('@/views/project/ProjectDetail') },
              { path: 'strategy/list', name: 'AlertStrategyList', meta: { title: '告警策略' }, component: () => import('@/views/alert-strategy/AlertStrategyList') },
              { path: 'strategy/:id/detail', name: 'AlertStrategyDetail', meta: { title: '告警策略详情', hidden: true }, component: () => import('@/views/alert-strategy/AlertStrategyDetail') },
              { path: 'strategy/create', name: 'AlertStrategyCreate', meta: { title: '创建告警策略', hidden: true }, component: () => import('@/views/alert-strategy/AlertStrategyCreate') },
              { path: 'category/list', name: 'AlertCategoryList', meta: { title: '告警分类' }, component: () => import('@/views/AlertCategoryList') },
              { path: 'user/list', name: 'UserList', meta: { title: '用户管理' }, component: () => import('@/views/UserList') },
              { path: 'role/list', name: 'UserRoleList', meta: { title: '角色管理' }, component: () => import('@/views/RoleList') }
            ]
          }
        )
      }
      menuRoutes.push(
        {
          path: '/user/profile',
          name: 'UserProfile',
          meta: { title: '个人中心', hidden: true },
          component: () => import('@/views/UserProfile')
        },
        {
          path: '*',
          name: 'Error404',
          meta: { title: '404', hidden: true },
          component: () => import('@/views/error/Error404')
        }
      )
      routes.push({
        path: '/',
        name: 'Index',
        meta: { title: '首页' },
        redirect: '/big-screen',
        component: MainLayout,
        children: menuRoutes
      })
      return new Promise(resolve => {
        commit('SET_ROUTES', routes)
        commit('SET_MENU_ROUTES', menuRoutes)
        resolve()
      })
    }
  }
}
