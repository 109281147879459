<template>
  <a-dropdown style="user-select: none;">
    <a-space>
      <a-avatar icon="user"></a-avatar>
      <span>{{ nickname || '用户' }}</span>
      <a-icon type="caret-down"></a-icon>
    </a-space>
    <a-menu slot="overlay">
      <template v-if="nickname">
        <a-menu-item>
          <a @click="$router.push({ name: 'UserProfile' })">
            <a-space>
              <a-icon type="profile"></a-icon>
              <span>个人中心</span>
            </a-space>
          </a>
        </a-menu-item>
        <a-menu-divider></a-menu-divider>
        <a-menu-item>
          <a @click="click">
            <a-space>
              <a-icon type="logout"></a-icon>
              <span>退出登录</span>
            </a-space>
          </a>
        </a-menu-item>
      </template>
      <a-menu-item v-else>
        <a @click="$router.push({ name: 'Login' })">
          <a-space>
            <a-icon type="login"></a-icon>
            <span>登录</span>
          </a-space>
        </a>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DropdownMenu',
  computed: {
    ...mapGetters(['nickname'])
  },
  methods: {
    ...mapActions(['Logout']),
    click () {
      this.$confirm({
        centered: true,
        content: '要注销登录吗？',
        title: '提示',
        onOk: () => {
          this.Logout().then(() => {
            window.location.reload()
          })
        }
      })
    }
  }
}
</script>
